<style>
.multiselect__single {
    white-space: nowrap;
    overflow: hidden;
}

.p-multiSelect {
    width: 760px !important
}

.selectSociety {
    width: 760px !important;
    /* max-width: fit-content; */
}
</style>

<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="stackGrade.id === null">Associate Stack Grade</h5>
                <h5 v-if="stackGrade.id !== null">Associate Stack Grade</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="region_id" label="Region">
                                    <validation-provider #default="{ errors }" name="Region" rules="">
                                        <b-form-select id="region_id" name="region_id" size="md"
                                            v-model="stackGrade.region_id" @change="changeRegion(stackGrade.region_id)">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in locations" :key="location.id"
                                                :value="location">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                            serverErrors.region_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group labe-for="location_id" label="Area">
                                    <validation-provider #default="{ errors }" name="Area" rules="">
                                        <b-form-select id="location_id" name="location_id" size="md"
                                            v-model="stackGrade.location_id" @change="getWarehouse(stackGrade.location_id)">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in locaChildren" :key="location.id"
                                                :value="location.id">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                            serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                        </b-row>
                        <b-row>

                            <b-col cols="6">
                                <b-form-group labe-for="name" label="Warehouse">
                                    <validation-provider #default="{ errors }" name="Warehouse" rules="">
                                        <b-form-select id="name" name="name" size="md" v-model="stackGrade.warehouse_id"
                                            @change="getStack(stackGrade.warehouse_id)">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                                :value="warehouse.id">
                                                {{ warehouse.name.toUpperCase() }} ({{ warehouse.code }})
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.warehouse_id">{{
                                            serverErrors.warehouse_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="name" label="Stack">
                                    <validation-provider #default="{ errors }" name="Stack" rules="">
                                        <b-form-select id="name" name="name" size="md" v-model="stackGrade.stack_id">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="stack in stacks" :key="stack.id" :value="stack.id">
                                                {{ stack.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.stack_id">{{
                                            serverErrors.stack_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                        </b-row>

                        <b-row>


                            <b-col cols="12">

                                <!-- <b-form-group labe-for="Societies" label="Societies">
                                    <MultiSelect v-model="selectedGrades" :options="grades" filter optionLabel="name"
                                        optionValue="id" display="chip" placeholder="Select Societies"
                                        :selectedItemsLabel="3" class="w-full md:w-50rem selectSociety" />
                                </b-form-group> -->

                                <b-form-group labe-for="group_id" label="Grade Group">
                                    <validation-provider #default="{ errors }" name="Grade Group" rules="">
                                        <b-form-select id="group_id" name="group_id" size="md"
                                            v-model="stackGrade.group_id">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="grade in grades" :key="grade.id" :value="grade.id">
                                                {{ grade.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.group_id">{{
                                            serverErrors.group_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                        </b-row>

                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->



        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Associate</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>

                <b-row class="mt-1">
                    <b-col>
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="stackGrade.region_id"
                                    @change="changeRegion(stackGrade.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col>
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md"
                                    v-model="stackGrade.location_id" @change="getWarehouse(stackGrade.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locaChildren" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col>
                        <b-form-group labe-for="name" label="Warehouse">
                            <validation-provider #default="{ errors }" name="Warehouse" rules="">
                                <b-form-select id="name" name="name" size="md" v-model="stackGrade.warehouse_id"
                                    @change="getStack(stackGrade.warehouse_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                        :value="warehouse.id">
                                        {{ warehouse.name.toUpperCase() }}  ({{ warehouse.code }})
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.warehouse_id">{{
                                    serverErrors.warehouse_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>


                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="stacks" responsive
                :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(groups)="data">
                    <span v-if="data.item.groups.length > 0">   {{ data.item.groups[0].name }}</span>
                    <span v-if="data.item.groups.length <= 0"></span>
                </template>

                <template #cell(bales)="data">
                    {{ new Intl.NumberFormat().format(data.item.stock.stockBales) }}
                </template>

                <template #cell(mass)="data">
                    {{ new Intl.NumberFormat().format(parseFloat(data.item.stock.stockMass).toFixed(2)) }}
                </template>

                <!-- Column: Actions -->
              
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BBadge, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import stackGradesStoreModule from '@/views/cromis/warehouse/stack_grade/stackGradesStoreModule'
import useStacksGradeList from '@/views/cromis/warehouse/stack_grade/useStacksGradeList'
import MultiSelect from 'primevue/multiselect';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
        ValidationObserver, ValidationProvider, MultiSelect, BFormTextarea
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const markets = ref([])
        const warehouses = ref([])
        const stacks = ref([])
        const grades = ref()
        const selectedGrades = ref([]);
        const areaSocieties = ref([]);
        const areaSocietiesToEdit = ref([]);
        const locations = ref([])
        const locaChildren = ref([])
        const client = ref(JSON.parse(localStorage.getItem('userData')).client)
        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        console.log(locId)

        const stackGrade = ref({
            id: null,
            location_id: null,
            warehouse_id: null,
            stack_id: null,
            region_id: null,
            market_id: null,
            group_id: null,
        })


        const CROMIS_STORE_MODULE_NAME = 'cromis-stack_grade'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, stackGradesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })


        onMounted(async () => {

            // console.log(locId);

            await store.dispatch('cromis-stack_grade/locations')
                .then(response => {
                    locations.value = response.data.locations
                    populateSelections(response.data.locations)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-stack_grade/grades')
                .then(response => {
                    grades.value = response.data.groups
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })


        const populateSelections = (locations) => {
            // console.log(locations)
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }


        const showSelected = () => {
            console.log(selectedGrades)
        }

        const changeRegion = async (event) => {
            console.log(event)
            locaChildren.value = event.children

            
            await store.dispatch('cromis-stack_grade/getWarehouse', { location_id: event.id })
                .then(response => {
                    warehouses.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            // locationFilter.value = event.id
            // filter.value.stackGrade_id = event.id
        }

        const getWarehouse = async (location) => {

            await store.dispatch('cromis-stack_grade/getWarehouse', { location_id: stackGrade.value.location_id })
                .then(response => {
                    warehouses.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getStack = async (location) => {

            await store.dispatch('cromis-stack_grade/getStack', { warehouse_id: stackGrade.value.warehouse_id })
                .then(response => {
                    stacks.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }


        const changeMarket = async () => {
            market_id.value = stackGrade.value.market_id
        }

        const getMarket = async (loc_id) => {
            await store.dispatch('cromis-stack_grade/grades', { location_id: location.id })
                .then(response => {
                    grades.value = response.data.grades
                    grades.value.forEach(el => {
                        areaSocieties.value.push({ "id": el.id, "name": el.name })
                    });
                    // console.log(areaSocieties.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }


        const changeFormSelection = async (location, index) => {
            // console.log(location, index)
            market_id.value = null
            stackGrade.value.market_id = null

            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }

                stackGrade.value.location_id = null
            }
            else {
                stackGrade.value.location_id = null

                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }

                if (index === formSelections.value.length - 1) {
                    stackGrade.value.location_id = location.id
                }
            }

            await store.dispatch('cromis-stack_grade/grades', { location_id: location.id })
                .then(response => {
                    grades.value = response.data.grades
                    grades.value.forEach(el => {
                        areaSocieties.value.push({ "id": el.id, "name": el.name })
                    });
                    // console.log(areaSocieties.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-stack_grade/markets', { location_id: location.id })
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }


        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
            season_id,
            buyer_id,
            market_id,

            // Resolvers
            resolveStatusVariant,
        } = useStacksGradeList()

        // Form Methods
        const invokeUpdateForm = (item) => {
           

            stackGrade.value = {
                id: item.id,
                market_id: item.market.id,
                warehouse_id: item.warehouse_id,
                stack_id: item.stack_id,
                group_id: selectedGrades,
            }

            myModal.value.show()
        }


        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            // console.log(formSelections.value)
            selectedGrades.value = [];

            stackGrade.value = {
                id: null,
                location_id: stackGrade.value.location_id,
                market_id: stackGrade.value.market_id,
                group_id: selectedGrades,
                region_id: null,
                warehouse_id: null,
                stack_id: null,

            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (stackGrade.value.id === null || stackGrade.value.id === 0)
                handleCreate()
            else
                handleUpdate(stackGrade.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()
            stackGrade.value.market_id = market_id.id
            if (!valid) {
                saving.value = false
                return
            }

            let grade = {
                "group_id": stackGrade.value.group_id
            }

            await store.dispatch('cromis-stack_grade/updateStack', { id: stackGrade.value.stack_id, data: grade })
                .then(response => {
                    refetch()
                    saving.value = false

                    stackGrade.value = {
                        id: null,
                        location_id: null,
                        warehouse_id: null,
                        stack_id: null,
                        region_id: null,
                        market_id: null,
                        group_id: null,
                    }

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Stack Group is associated successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-stack_grade/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to stackGrade # ${response.data.number} of ${response.data.market.code} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        
        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the  Grade Association?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Grade Association is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }


        const remove = async (id) => {
            await store.dispatch('cromis-stack_grade/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            client,
            selections,
            formSelections,
            markets,
            stackGrade,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            locId,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            changeMarket,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            confirmDelete,
            remove,
            resolveStatusVariant,
            selectedGrades,
            areaSocieties,
            areaSocietiesToEdit,
            grades,
            showSelected,
            locations,
            changeRegion,
            locaChildren,
            getWarehouse,
            warehouses,
            stacks,
            getStack
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>